import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Title from "../title"
import styles from "../../css/about.module.css"

const getPublic = graphql`
  query publicImage {
    publicImage: file(relativePath: { eq: "contactBcg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Public = () => {
  const { publicImage } = useStaticQuery(getPublic)

  return (
    <section id="public" className={styles.about}>
      <Title title="Öffentlichkeitsarbeit" />
      <div className={styles.aboutCenter}>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}>
            <Img
              fluid={publicImage.childImageSharp.fluid}
              alt="public #VsCovidUnited"
            />
          </div>
        </article>
        <article className={styles.aboutInfo}>
          <h4>Tue Gutes und rede darüber.</h4>
          <p>
            Wir organisieren eine umfangreiche PR- und Öffentlichkeitsarbeit,
            natürlich um die Plattform bekannt zu machen, aber auch ihre
            Erfolgsgeschichten und ihre Teilnehmer.
          </p>
          <AniLink fade to="/contact" className="btn-primary">
            Schreiben Sie uns
          </AniLink>
        </article>
      </div>
    </section>
  )
}

export default Public
