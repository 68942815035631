import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import StyledHero from "../components/styledHero"
import Registration from "../components/service/registration"
import Matching from "../components/service/matching"
import Grouping from "../components/service/grouping"
import Search from "../components/service/search"
import Realisation from "../components/service/realisation"
import Offer from "../components/service/offer"
import Public from "../components/service/public"
import SEO from "../components/seo"

const Service = ({ data, location }) => {
  return (
    <Layout location={location.pathname}>
      <SEO title="Service" />
      <StyledHero img={data.serviceBcg.childImageSharp.fluid} />
      <Registration />
      <Matching />
      <Grouping />
      <Search />
      <Realisation />
      <Offer />
      <Public />
    </Layout>
  )
}

export const query = graphql`
  query {
    serviceBcg: file(relativePath: { eq: "serviceBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Service
