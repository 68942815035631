import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Title from "../title"
import styles from "../../css/about.module.css"

const getRealisation = graphql`
  query realisationImage {
    realisationImage: file(relativePath: { eq: "wikiBcg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Realisation = () => {
  const { realisationImage } = useStaticQuery(getRealisation)

  return (
    <section id="realisation" className={styles.about}>
      <Title title="Umsetzung von" subtitle="Stützungsmaßnahmen" />
      <div className={styles.aboutCenter}>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}>
            <Img
              fluid={realisationImage.childImageSharp.fluid}
              alt="realisation #VsCovidUnited"
            />
          </div>
        </article>
        <article className={styles.aboutInfo}>
          <h4>Umsetzung von finanziellen Stützungsmaßnahmen</h4>
          <p>Es geht auch ums wirtschaftliche Überleben.</p>
          <ul>
            <li>Wir suchen aktiv nach budgetierten Umsetzungsprojekten.</li>
            <li>
              Wir machen uns für öffentliche Fördermittel für die Initiative
              stark.
            </li>
            <li>Wir sammeln Spenden und Sponsorengelder.</li>
            <li>
              Wir bauen mit Investoren einen private equity basierten Hilfsfonds
              auf, der gegen eine jederzeit abwickelbare Minderheitsbeteiligung
              an hilfebedürftigen Unternehmen die entsprechenden
              Projektleistungen für diese bezahlt.
            </li>
          </ul>
          <AniLink fade to="/join" className="btn-primary">
            Mitmachen!
          </AniLink>
        </article>
      </div>
    </section>
  )
}

export default Realisation
