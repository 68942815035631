import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Title from "../title"
import styles from "../../css/about.module.css"

const getsearch = graphql`
  query searchImage {
    searchImage: file(relativePath: { eq: "defaultBcg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Search = () => {
  const { searchImage } = useStaticQuery(getsearch)

  return (
    <section id="search" className={`${styles.about} ${styles.even}`}>
      <Title title="Aktive" subtitle="Suche" />
      <div className={styles.aboutCenter}>
        <article className={styles.aboutInfo}>
          <h4>
            Aktive Suche nach Anbietern und Nachfragern, wo sich Angebot und
            Nachfrage nicht decken
          </h4>
          <p>
            Nicht jeder Topf findet sofort seinen Deckel. Wir scouten nach
            Projekten, für die es über die Plattform ein Angebot gäbe und vice
            versa.
          </p>
          <AniLink fade to="/join" className="btn-primary">
            Mitmachen!
          </AniLink>
        </article>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}>
            <Img
              fluid={searchImage.childImageSharp.fluid}
              alt="search #VsCovidUnited"
            />
          </div>
        </article>
      </div>
    </section>
  )
}

export default Search
