import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Title from "../title"
import styles from "../../css/about.module.css"

const getGrouping = graphql`
  query groupingImage {
    groupingImage: file(relativePath: { eq: "serviceBcg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Grouping = () => {
  const { groupingImage } = useStaticQuery(getGrouping)

  return (
    <section id="grouping" className={styles.about}>
      <Title title="Bündelung von" subtitle="Hilfsangeboten" />
      <div className={styles.aboutCenter}>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}>
            <Img
              fluid={groupingImage.childImageSharp.fluid}
              alt="grouping #VsCovidUnited"
            />
          </div>
        </article>
        <article className={styles.aboutInfo}>
          <h4>
            Die Bündelung verschiedener Hilfsangebote für dediziert nachgefragte
            Projekte oder zur Herstellung relevanter interdisziplinärer
            Serviceangebote
          </h4>
          <p>
            Wir helfen aktiv nach. Manchmal entsteht ein attraktives Angebot vor
            allem durch die Kombination unterschiedlicher Leistungen. Wir
            analysieren den Marktplatz regelmäßig nach Möglichkeiten und stoßen
            die Zusammenarbeit an.
          </p>
          <AniLink fade to="/join" className="btn-primary">
            Mitmachen!
          </AniLink>
        </article>
      </div>
    </section>
  )
}

export default Grouping
