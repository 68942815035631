import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Title from "../title"
import styles from "../../css/about.module.css"

const getmatching = graphql`
  query matchingImage {
    matchingImage: file(relativePath: { eq: "aboutBcg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Matching = () => {
  const { matchingImage } = useStaticQuery(getmatching)

  return (
    <section id="matching" className={`${styles.about} ${styles.even}`}>
      <Title title="Systematische" subtitle="Zusammenführung" />
      <div className={styles.aboutCenter}>
        <article className={styles.aboutInfo}>
          <h4>Systematische Zusammenführung von Angebot und Nachfrage</h4>
          <p>
            It’s a match! Unser Partner Knooing stellt uns seine technische,
            selbst lernende Matching Plattform zur Verfügung. Diese bringt
            Angebote und Gesuche auf Basis von Algorithmen automatisiert
            zusammen.
          </p>
          <AniLink fade to="/join" className="btn-primary">
            Mitmachen!
          </AniLink>
        </article>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}>
            <Img
              fluid={matchingImage.childImageSharp.fluid}
              alt="matching #VsCovidUnited"
            />
          </div>
        </article>
      </div>
    </section>
  )
}

export default Matching
