import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Title from "../title"
import styles from "../../css/about.module.css"

const getoffer = graphql`
  query offerImage {
    offerImage: file(relativePath: { eq: "showroomBcg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Offer = () => {
  const { offerImage } = useStaticQuery(getoffer)

  return (
    <section id="offer" className={`${styles.about} ${styles.even}`}>
      <Title title="Angebot für" subtitle="Leistungen" />
      <div className={styles.aboutCenter}>
        <article className={styles.aboutInfo}>
          <h4>Angebot unmittelbarer Unterstützungsleistungen</h4>
          <p>
            Wir bieten zum einen als Plattformpartner selbst
            Unterstützungsleistungen an und stellen zum anderen direkt abrufbare
            Serviceprodukte von Anbietern in unserem Showroom aus.
          </p>
          <AniLink fade to="/showroom" className="btn-primary">
            Zu den Angeboten
          </AniLink>
        </article>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}>
            <Img
              fluid={offerImage.childImageSharp.fluid}
              alt="offer #VsCovidUnited"
            />
          </div>
        </article>
      </div>
    </section>
  )
}

export default Offer
