import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Title from "../title"
import styles from "../../css/about.module.css"

const getRegistration = graphql`
  query registrationImage {
    registrationImage: file(relativePath: { eq: "rulesBcg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Registration = () => {
  const { registrationImage } = useStaticQuery(getRegistration)

  return (
    <section id="registration" className={styles.about}>
      <Title title="Erfassung &" subtitle="Spezifikation" />
      <div className={styles.aboutCenter}>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}>
            <Img
              fluid={registrationImage.childImageSharp.fluid}
              alt="registration #VsCovidUnited"
            />
          </div>
        </article>
        <article className={styles.aboutInfo}>
          <h4>
            Erfassung und Spezifikation von Hilfegesuchen und Hilfeangeboten
          </h4>
          <p>
            Wir erfassen Projektanfragen und Unterstützungsanfragen von
            Unternehmen, Vereinen, Verbänden, Hilfsorganisationen und
            -initiativen, Stiftungen und (halb-) öffentlichen Einrichtungen.
          </p>
          <p>
            Wir erfassen Hilfsangebote primär von Service Professionals und
            helfen, wenn nötig bei der Spezifikation der angebotenen Leistung,
            um ein möglichst gutes Matching zu ermöglichen.
          </p>
          <AniLink fade to="/join" className="btn-primary">
            Mitmachen!
          </AniLink>
        </article>
      </div>
    </section>
  )
}

export default Registration
